<template>
  <div>
    <b-overlay :show="showOverlay" rounded="sm">
      <b-card style="max-width: 100%; width: 100%" class="mb-2">
        <b-tabs content-class="mt-3" pills>
          <b-tab :title="$t('COMMON.MAIN')" active>
            <b-card-body>
              <b-form-group
                id="field-name"
                label-cols-lg="2"
                :label="$t('DEVICE.VALUE')"
                label-for="field-name"
                v-if="dto.id != null"
              >
                <b-form-input
                  id="field-name-input"
                  v-model="dto.id"
                  disabled
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="field-user-name"
                label-cols-lg="2"
                :label="$t('COMMON.USER')"
                label-for="field-user-name"
              >
                <!--<b-form-input id="field-name-input" v-model="dto.id" disabled></b-form-input>-->
                <vue-typeahead-bootstrap
                  id="user"
                  v-model="dto.userName"
                  :minMatchingChars="1"
                  trim
                  size="sm"
                  @input="
                    requestSuggestionVariants(
                      'userSuggestionProcessor',
                      dto.userName
                    )
                  "
                  :data="suggestedVariants"
                  :serializer="(s) => suggestFormater(s)"
                  @hit="onUserSelected"
                  :placeholder="$t('TRANSACTIONS.TYPE_USER_NAME')"
                  style="min-width: 30%"
                  ref="userTypeahead"
                >
                </vue-typeahead-bootstrap>
              </b-form-group>

              <b-form-group
                id="field-identifier-value"
                label-cols-lg="2"
                :label="$t('TOKENS.IDENTIFIER')"
                label-for="field-identifier-value"
              >
                <!--<b-form-input id="field-name-input" v-model="dto.id" disabled></b-form-input>-->
                <vue-typeahead-bootstrap
                  id="identifier"
                  v-model="dto.identifierValue"
                  :minMatchingChars="1"
                  trim
                  size="sm"
                  @input="
                    requestSuggestionVariants(
                      'identifierSuggestionProcessor',
                      dto.identifierValue
                    )
                  "
                  :data="suggestedVariants"
                  :serializer="(s) => s.name"
                  @hit="onIdentifierSelected"
                  :placeholder="$t('TOKENS.TYPE_IDENTIFIER_VALUE')"
                  style="min-width: 30%"
                  ref="identifierTypeahead"
                >
                </vue-typeahead-bootstrap>
              </b-form-group>

              <b-form-group
                id="field-last-request"
                label-cols-lg="2"
                :label="$t('TOKENS.LAST_REQUEST')"
                label-for="field-last-request"
              >
                <single-date-picker :date.sync="dto.lastRequest">
                </single-date-picker>
              </b-form-group>

              <b-form-group
                id="field-visible"
                label-cols-lg="2"
                :label="$t('COMMON.ACTIVE')"
                label-for="field-visible-input"
              >
                <b-form-checkbox
                  id="checkbox-approved-input"
                  v-model="dto.active"
                  name="checkbox-visible"
                  class="pt-2"
                  switch
                  size="lg"
                >
                </b-form-checkbox>
              </b-form-group>
            </b-card-body>
          </b-tab>
        </b-tabs>

        <div style="float: right">
          <b-button variant="success" v-on:click="onSaveClicked">{{
            $t("COMMON.SAVE")
          }}</b-button>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { API_REQUEST } from "@/core/services/store/api.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import SuggestionProcessorMixin from "../mixins/suggestionProcessorMixin";
import SingleDatePicker from "../component/SingleDatePicker";

export default {
  name: "token",
  mixins: [SuggestionProcessorMixin],
  components: { SingleDatePicker },
  data() {
    return {
      save: {
        resource: "/platform/api/token/save",
        requestType: "POST",
        requestParams: {},
      },

      dto: {
        id: null,
        userName: null,
        value: null,
        active: false,
        userId: null,
        identifierId: null,
        identifierValue: null,
        lastRequest: null,
      },

      showOverlay: true,
      selectedUserId: null,
      selectedIdentifierId: null,
      createNewStr: this.$t("TOKENS.CREATE_NEW_TOKEN"),
      editStr: this.$t("TOKENS.EDIT_TOKEN"),
    };
  },

  mounted() {
    let title = !this.$route.params.id ? this.createNewStr : this.editStr;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("MENU.TOKENS"), route: "/tokens" },
      { title: title },
    ]);
  },

  created: async function () {
    if (this.$route.params.id != null) {
      await this.$store
        .dispatch(API_REQUEST, {
          resource: "/platform/api/token/" + this.$route.params.id,
          requestType: "GET",
        })
        .then((response) => (this.dto = response))
        .catch(this.onError);
    }

    this.showOverlay = false;
    if (this.dto.id != null) {
      this.$refs.userTypeahead.$data.inputValue = this.dto.userName;
      this.selectedUserId = this.dto.userId;

      this.$refs.identifierTypeahead.$data.inputValue =
        this.dto.identifierValue;
      this.selectedIdentifierId = this.dto.identifierId;
    }
  },

  methods: {
    onUserSelected: function (value) {
      this.selectedUserId = value.id;
    },

    onIdentifierSelected: function (value) {
      this.selectedIdentifierId = value.id;
    },

    onSaveClicked: function () {
      this.dto.userId = this.selectedUserId;
      this.dto.identifierId = this.selectedIdentifierId;
      const request = this.save;
      request.dto = this.dto;

      this.$store
        .dispatch(API_REQUEST, request)
        .then((response) => {
          this.dto.id = response.id;

          this.$bvToast.toast(this.$t("COMMON.SAVED_SUCCESSFULLY"), {
            title: this.$t("COMMON.SUCCESS"),
            variant: "success",
            autoHideDelay: 5000,
          });
        })
        .catch(this.onError);
    },

    getLang() {
      return localStorage.getItem("language") || "en";
    },

    onError: function (response) {
      if (response && response.config) response = response.data;

      this.$bvToast.toast(
        response && response.message
          ? response.message
          : this.$t("COMMON.UNKNOWN_ERROR"),
        {
          title: this.$t("COMMON.ERROR"),
          variant: "danger",
          autoHideDelay: 5000,
        }
      );
    },

    suggestFormater: function (s) {
      let str = s.name;
      if (s.description) {
        str = str + "\n(" + s.description + ")";
      }
      return str;
    },
  },
};
</script>
<style>
.hidden {
  display: none;
}
.form-group {
  margin-bottom: 1rem;
}
</style>
