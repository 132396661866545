var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-overlay',{attrs:{"show":_vm.showOverlay,"rounded":"sm"}},[_c('b-card',{staticClass:"mb-2",staticStyle:{"max-width":"100%","width":"100%"}},[_c('b-tabs',{attrs:{"content-class":"mt-3","pills":""}},[_c('b-tab',{attrs:{"title":_vm.$t('COMMON.MAIN'),"active":""}},[_c('b-card-body',[(_vm.dto.id != null)?_c('b-form-group',{attrs:{"id":"field-name","label-cols-lg":"2","label":_vm.$t('DEVICE.VALUE'),"label-for":"field-name"}},[_c('b-form-input',{attrs:{"id":"field-name-input","disabled":""},model:{value:(_vm.dto.id),callback:function ($$v) {_vm.$set(_vm.dto, "id", $$v)},expression:"dto.id"}})],1):_vm._e(),_c('b-form-group',{attrs:{"id":"field-user-name","label-cols-lg":"2","label":_vm.$t('COMMON.USER'),"label-for":"field-user-name"}},[_c('vue-typeahead-bootstrap',{ref:"userTypeahead",staticStyle:{"min-width":"30%"},attrs:{"id":"user","minMatchingChars":1,"trim":"","size":"sm","data":_vm.suggestedVariants,"serializer":(s) => _vm.suggestFormater(s),"placeholder":_vm.$t('TRANSACTIONS.TYPE_USER_NAME')},on:{"input":function($event){return _vm.requestSuggestionVariants(
                    'userSuggestionProcessor',
                    _vm.dto.userName
                  )},"hit":_vm.onUserSelected},model:{value:(_vm.dto.userName),callback:function ($$v) {_vm.$set(_vm.dto, "userName", $$v)},expression:"dto.userName"}})],1),_c('b-form-group',{attrs:{"id":"field-identifier-value","label-cols-lg":"2","label":_vm.$t('TOKENS.IDENTIFIER'),"label-for":"field-identifier-value"}},[_c('vue-typeahead-bootstrap',{ref:"identifierTypeahead",staticStyle:{"min-width":"30%"},attrs:{"id":"identifier","minMatchingChars":1,"trim":"","size":"sm","data":_vm.suggestedVariants,"serializer":(s) => s.name,"placeholder":_vm.$t('TOKENS.TYPE_IDENTIFIER_VALUE')},on:{"input":function($event){return _vm.requestSuggestionVariants(
                    'identifierSuggestionProcessor',
                    _vm.dto.identifierValue
                  )},"hit":_vm.onIdentifierSelected},model:{value:(_vm.dto.identifierValue),callback:function ($$v) {_vm.$set(_vm.dto, "identifierValue", $$v)},expression:"dto.identifierValue"}})],1),_c('b-form-group',{attrs:{"id":"field-last-request","label-cols-lg":"2","label":_vm.$t('TOKENS.LAST_REQUEST'),"label-for":"field-last-request"}},[_c('single-date-picker',{attrs:{"date":_vm.dto.lastRequest},on:{"update:date":function($event){return _vm.$set(_vm.dto, "lastRequest", $event)}}})],1),_c('b-form-group',{attrs:{"id":"field-visible","label-cols-lg":"2","label":_vm.$t('COMMON.ACTIVE'),"label-for":"field-visible-input"}},[_c('b-form-checkbox',{staticClass:"pt-2",attrs:{"id":"checkbox-approved-input","name":"checkbox-visible","switch":"","size":"lg"},model:{value:(_vm.dto.active),callback:function ($$v) {_vm.$set(_vm.dto, "active", $$v)},expression:"dto.active"}})],1)],1)],1)],1),_c('div',{staticStyle:{"float":"right"}},[_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.onSaveClicked}},[_vm._v(_vm._s(_vm.$t("COMMON.SAVE")))])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }